<template>
  <v-container fluid>
    <v-row class="mt-4" align="start">
      <v-col cols="12" md="6">
        <p class="title">
          {{ movementType && movementType === "insert" ? "Inclusão de Grupo Familiar:" : "Implantação:" }}
          Adicionar Titular
        </p>
      </v-col>
      <v-col cols="12" lg="6">
        <v-stepper tile value="1">
          <v-stepper-header>
            <v-stepper-step step="1" color="textPrimary">
              Informações do Beneficiário e do Plano
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4"></v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
          <v-card class="px-6 py-6" elevation="1">
            <v-form
              ref="formSearch"
              v-model="isValidFormSearch"
            >
              <v-row>
                <v-col cols="12">
                  <p class="title">Informações do Titular</p>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="CPF"
                    placeholder="Informe o CPF"
                    v-model="beneficiary.cpf"
                    v-mask="'###.###.###-##'"
                    :rules="beneficiary.cpf ? [rule.cpf] : []"
                    validate-on-blur
                    outlined
                    color="textPrimary"
                    :disabled="isDisabledBeneficiaryData"
                    @keyup.enter="onClickSearch()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="beneficiaryName"
                    label="Nome"
                    placeholder="Informe o nome"
                    maxlength="70"
                    v-model.trim="beneficiary.name"
                    outlined
                    color="textPrimary"
                    :disabled="isDisabledBeneficiaryData"
                    :rules="beneficiary.name ? [rule.validateIsAlphanumeric, rule.name] : []"
                    validate-on-blur
                    @blur="beneficiary.name = formatter.formatToTitleCase(beneficiary.name)"
                    @keyup.enter="onClickSearch()"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <div class="d-flex flex-column">
                    <v-text-field
                      outlined
                      label="Data de Admissão"
                      v-model="beneficiary.admissionDate"
                      append-icon="fas fa-calendar-alt"
                      v-mask="'##/##/####'"
                      placeholder="DD/MM/YYYY"
                      color="textPrimary"
                      :rules="beneficiary.admissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid] : []"
                      validate-on-blur
                      :disabled="isDisabledBeneficiaryData"
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn
                    x-large
                    dark
                    color="textPrimary"
                    width="250px"
                    :loading="loadingSearchButton"
                    @click="onClickSearch()"
                  >
                    Pesquisar
                  </v-btn>
                </v-col>
                <v-col cols="12" md="9">
                  <v-alert
                    :value="alert.show"
                    transition="fade-transition"
                    class="py-4"
                    icon="mdi-alert"
                    color="textPrimary"
                    dense
                    outlined
                  >
                    {{ alert.text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12">
                <p class="title">Informações do Plano</p>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="beneficiary.plans"
                  :items-per-page="10"
                  hide-default-footer
                  item-key="id"
                  :page="page"
                >
                  <template v-slot:no-data>
                    <span>Nenhum item foi encontrado.</span>
                  </template>

                  <template v-slot:[`item.financialGroup`]="{ item }">
                    <v-autocomplete
                      :items="financialGroups"
                      :loading="isLoadingFinancialGroups"
                      v-model="item.financialGroup"
                      placeholder="Grupo Financeiro"
                      label="Grupo Financeiro"
                      item-text="name"
                      item-value="id"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      :disabled="beneficiary && beneficiary.plans.length > 1"
                      :rules="[rule.required]"
                      validate-on-blur
                      @input="loadContractByFinancialGroup(item)"
                    />
                  </template>

                  <template v-slot:[`item.contract`]="{ item }">
                    <v-autocomplete
                      class="my-4"
                      v-model="item.contract"
                      :items="item.contracts"
                      :loading="isLoadingContract"
                      item-text="text"
                      item-value="contract"
                      placeholder="Contrato"
                      label="Contrato"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      :rules="item.contract !== null ? [] : [rule.required]"
                      validate-on-blur
                      @input="loadSubcontractByContractId(item)"
                    />
                  </template>

                  <template v-slot:[`item.subcontract`]="{ item }">
                    <v-autocomplete
                      class="my-4"
                      v-model="item.subcontract"
                      :items="item.subcontracts"
                      :loading="isLoadingSubContract"
                      item-text="text"
                      item-value="subcontract"
                      placeholder="Subcontrato"
                      label="Subcontrato"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      :rules="item.subcontract !== null ? [] : [rule.required]"
                      validate-on-blur
                      @input="loadEligibilityByContractIdAndSubcontractId(item)"
                    />
                  </template>

                  <template v-slot:[`item.eligibility`]="{ item }">
                    <v-autocomplete
                      class="my-4"
                      v-model="item.eligibility"
                      :items="item.eligibilities"
                      :loading="isLoadingEligibility"
                      item-text="elegibility_id.name"
                      item-value="id"
                      placeholder="Elegibilidade"
                      label="Elegibilidade"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      :rules="item.eligibility !== null ? [] : [rule.required]"
                      @input="loadPlansByEligibility(item);"
                      validate-on-blur
                    />
                  </template>

                  <template v-slot:[`item.plan`]="{ item }">
                    <v-autocomplete
                      class="my-4"
                      v-model="item.plan"
                      :items="item.plans"
                      :loading="isLoadingPlan"
                      item-text="name"
                      item-value="id"
                      placeholder="Plano"
                      label="Plano"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      :rules="item.plan !== null ? [] : [rule.required]"
                      validate-on-blur
                    />
                  </template>

                  <template v-slot:[`item.validityDate`]="{ item }">
                    <div class="d-flex flex-column">
                      <v-text-field
                        label="Início De Vigência"
                        v-model="item.validityDate"
                        append-icon="fas fa-calendar-alt"
                        placeholder="DD/MM/YYYY"
                        v-mask="'##/##/####'"
                        color="textPrimary"
                        :rules="item.validityDate !== null ? [] : [rule.required, rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.endDateIsGreaterThanOrEqualToStartDate(beneficiary.admissionDate, item.validityDate, 'Data início de vigência deve ser maior ou igual a data de admissão')]"
                        validate-on-blur
                      />
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      v-if="!isCriticizedCarrier"
                      class="mt-3"
                      color="textPrimary"
                      dark
                      icon
                      @click="onClickAdd()"
                    >
                      <v-icon size="20" color="textPrimary">fas fa-plus</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="beneficiary && beneficiary.plans && beneficiary.plans.length === 1"
                      color="textPrimary"
                      dark
                      icon
                      class="mt-3"
                      @click="onClickDel(item)"
                    >
                      <v-icon color="textPrimary" size="20"
                        >fas fa-minus</v-icon
                      >
                    </v-btn>
                  </template>
                </v-data-table>
                <v-row class="mt-2 mb-2" justify="end">
                  <v-pagination
                    color="textPrimary"
                    v-model="page"
                    :length="Math.ceil(beneficiary && beneficiary.plans ? beneficiary.plans.length / 10 : 0)"
                    :total-visible="9"
                  />
                </v-row>
              </v-col>
            </v-row>
            <div v-if="violations && violations.length >0">
              <ViolationRulesAlert  :violations="violations"/>
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-footer>
      <v-row class="d-flex pt-6 justify-end">
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Voltar
        </v-btn>
        <v-btn
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          :loading="isLoadingValidation"
          @click="onClickFinish()"
        >
          {{ continueWithCriticism ? 'Prosseguir com crítica' : 'Próximo' }}
        </v-btn>
      </v-row>
    </v-footer>
    <BeneficiaryDialog
      :show="dialog.show"
      :beneficiaries="beneficiaries"
      @confirm="confirmBeneficiaryDialog"
      @close="closeBeneficiaryDialog"
    />

    <SnackbarCustomize ref="SnackbarCustomize" />

    <div v-if="isCriticizedCarrier">
      <ViewCriticizedViolationDialog />
    </div>
  </v-container>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import ContractService from '@/services-http/contract/ContractService';
import PhysicalPersonService from '@/services-http/contract/PhysicalPersonService';
import Rules from '@/shared/validators/formRules';
import BeneficiaryDialog from '@/components/BeneficiaryDialog/BeneficiaryDialog.vue';
import ContractEligibilityService from '@/services-http/contract/ContractEligibilityService';
import ContractProductService from '@/services-http/contract/ContractProductService';
import lodash from 'lodash';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import MovementRecordFormFieldsToValidate from '@/shared/validators/movementRecordFormFieldsToValidate';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import PayrollService from '@/services-http/sdi/PayrollService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default {
  name: 'BeneficiaryAdditionalInformation',
  components: { BeneficiaryDialog, ViewCriticizedViolationDialog, ViolationRulesAlert, SnackbarCustomize },
  data: () => ({
    alert: {
      show: false,
      text: '',
      error: {
        notFound: 'Não foram encontrados resultados para a pesquisa.',
        emptySearch: 'Por favor, preencha um CPF válido ou o nome para pesquisar.',
        isContractSelected: 'Este contrato já foi selecionado. Por favor, selecione um contrato diferente.',
        hasDuplicated: 'Existem contratos duplicados. Por favor, verifique o preenchimento.',
        genericError: 'Ocorreu um erro ao buscar o beneficiário.',
      },
    },
    fab: null,
    beneficiary: {
      cpf: null,
      name: null,
      admissionDate: null,
      plans: [
        {
          contract: null,
          contracts: null,
          subcontract: null,
          subcontracts: null,
          eligibility: null,
          eligibilities: null,
          plan: null,
          plans: null,
          validityDate: null,
        },
      ],
    },
    dialog: { show: false },
    financialGroupDefault: null,
    financialGroups: [],
    fieldsToRulesValidation: [],
    headers: [
      {
        text: 'Grupo Financeiro',
        value: 'financialGroup',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Subcontrato',
        value: 'subcontract',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Elegibilidade',
        value: 'eligibility',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Início de Vigência',
        value: 'validityDate',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    isValidForm: true,
    isValidFormSearch: true,
    isDisabledBeneficiaryData: false,
    loading: false,
    loadingSearchButton: false,
    movementType: null,
    page: 1,
    beneficiaries: [],
    isRHProtegido: false,
    insuranceCarrierId: null,
    isLoadingFinancialGroups: false,
    isLoadingContract: false,
    isLoadingSubContract: false,
    isLoadingEligibility: false,
    isLoadingPlan: false,
    isCriticizedCarrier: false,
    isLoadingValidation: false,
    hasViolation: false,
    violations: [],
    continueWithCriticism: false,
  }),
  watch: {
    beneficiary: {
      handler(val) {
        if(this.continueWithCriticism){
          this.continueWithCriticism=false;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    await this.loadFinancialGroups();
    await this.loadDataSessionStorage();
  },

  mixins: [VerifyRoutesMixin],

  methods: {
    confirmBeneficiaryDialog(item) {
      this.clearSessionStorage();
      this.dialog.show = false;
      this.beneficiary.name = this.formatter.formatToTitleCase(item.name);
      this.beneficiary.admissionDate = item.admissionDate ? this.formatter.formatDate(item.admissionDate) : item.admission_date ? this.formatter.formatDate(item.admission_date) : null;
      this.beneficiary.cpf = item.cpf ? this.formatter.formatCpf(item.cpf) : item.documentNumber ? this.formatter.formatCpf(item.documentNumber) : null;
      this.$nextTick(() => {
        this.$refs.beneficiaryName.focus();
      });
      sessionStorage.setItem('hasHolder', JSON.stringify(item));
    },
    closeBeneficiaryDialog() {
      this.dialog.show = false;
    },
    closeAlert() {
      setTimeout(() => {
        this.alert.show = false;
        this.alert.text = '';
      }, 5000);
    },
    async loadDataSessionStorage() {
      sessionStorage.removeItem('selectedDate');
      if (sessionStorage.getItem('beneficiaryContractedPlan')) {
        const beneficiaryStore = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));
        this.violations = sessionStorage.getItem('beneficiaryContractedPlanViolations') ? JSON.parse(sessionStorage.getItem('beneficiaryContractedPlanViolations')) : [];

        if (beneficiaryStore && beneficiaryStore.plans && beneficiaryStore.plans.length > 0) {
          beneficiaryStore.admissionDate = this.formatter.formatDate(beneficiaryStore.admissionDate);
          beneficiaryStore.plans = beneficiaryStore.plans.map((plan) => ({
            ...plan,
            eligibility: plan.eligibilityInternalId ? plan.eligibilityInternalId : plan.eligibility,
            validityDate: this.formatter.formatDate(plan.validityDate),
          }));

          this.beneficiary = {};
          this.beneficiary = await lodash.cloneDeep(beneficiaryStore);

          this.financialGroupDefault = beneficiaryStore.plans[0].financialGroup;

          if (sessionStorage.getItem('isCriticizedCarrier')) {
            this.isCriticizedCarrier = true;
            this.loadContractByFinancialGroup(this.beneficiary.plans[0]);
          }
        }
      }
    },
    onClickAdd() {
      const plan = {
        financialGroup: this.financialGroupDefault,
        contract: null,
        contracts: null,
        subcontract: null,
        subcontracts: null,
        eligibility: null,
        eligibilities: null,
        plan: null,
        plans: null,
        validityDate: null,
      };

      this.beneficiary.plans.unshift(plan);
      if (this.beneficiary.plans.length > 1) {
        this.loadContractByFinancialGroup(this.beneficiary.plans[0]);
      }
    },
    onClickDel(item) {
      if (this.beneficiary && this.beneficiary.plans && this.beneficiary.plans.length > 0) {
        const elementPosition = this.beneficiary.plans.indexOf(item);
        this.beneficiary.plans.splice(elementPosition, 1);
      }

      if (this.beneficiary && this.beneficiary.plans && !this.beneficiary.plans.length > 0) {
        this.financialGroup = null;
      }
    },
    clearSessionStorage() {
      const keyToken = '@auth/token';
      const keyUser = '@auth/user';
      const valueToken = sessionStorage.getItem(keyToken);
      const valueUser = sessionStorage.getItem(keyUser);
      sessionStorage.clear();
      sessionStorage.setItem(keyToken, valueToken);
      sessionStorage.setItem(keyUser, valueUser);
    },
    onClickCancel() {
      this.clearSessionStorage();
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    onClickFinish() {
      if (!this.continueWithCriticism) {
        this.violations = [];
      }

      if (this.beneficiary.cpf && this.beneficiary.name && this.beneficiary.admissionDate) {
        if (!this.$refs.formRegister.validate()) {
          return;
        }

        if (this.hasDuplicatedContracts()) {
          this.alert.show = true;
          this.alert.text = this.alert.error.hasDuplicated;
          this.closeAlert();
          return;
        }

        let plans = [];
        if (this.beneficiary && this.beneficiary.plans && this.beneficiary.plans.length) {
          plans = this.beneficiary.plans.map((p) => ({
            index: p.index,
            financialGroup: p.financialGroup,
            contract: p.contract,
            contracts: p.contracts,
            subcontract: p.subcontract,
            subcontracts: p.subcontracts,
            eligibility: p.eligibility,
            eligibilityInternalId: p.eligibility,
            eligibilities: p.eligibilities,
            plan: p.plan,
            plans: p.plans,
            validityDate: this.formatter.formatDateBRtoString(p.validityDate),
          }));
        }

        const beneficiaryContractedPlan = {
          cpf: this.formatter.removeNonDigit(this.beneficiary.cpf),
          name: this.beneficiary.name,
          admissionDate: this.formatter.formatDateBRtoString(this.beneficiary.admissionDate),
          plans,
        };
        const insurance = beneficiaryContractedPlan.plans[0].subcontracts[0].insuranceCarrierId;
        sessionStorage.setItem('beneficiaryContractedPlan', JSON.stringify(beneficiaryContractedPlan));
        const query = {
          movementType: this.movementType,
          isRHProtegido: this.isRHProtegido,
          insuranceCarrierId: insurance,
          beneficiaryType: 'HOLDER',
        };

        this.isLoadingValidation = true;
        const insertToValidate = { contractedPlan: beneficiaryContractedPlan };
        const fieldsToValidate = this.fieldsToValidate.getFieldsToValidate('beneficiaryPlanInformation');
        const movementType = this.movementType ? this.movementType.toLowerCase() : null;
        const functionValidate = movementType === 'insert' ? this.movementRecordService.ValidateInsert(insertToValidate, fieldsToValidate) : this.movementRecordService.ValidateImplantation(insertToValidate, fieldsToValidate);

        if (!this.continueWithCriticism) {
          functionValidate.then(() => {
            this.isLoadingValidation = false;
            this.redirectRouter('BeneficiaryAdditionalInformation', query);
          }).catch((error) => {
            this.isLoadingValidation = false;
            if (error.response.status === 422) {
              this.violations = [...error.response.data.violations];
              this.continueWithCriticism = true;
              this.$refs.SnackbarCustomize.open('error', 'Erro de validação de regras no preenchimento do formulário');
            }
          });
        } else {
          sessionStorage.setItem('beneficiaryContractedPlanViolations', JSON.stringify(this.violations));
          this.redirectRouter('BeneficiaryAdditionalInformation', query);
        }
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Preencha todas as informações do titular');
      }
    },
    async onClickSearch() {
      if (!this.$refs.formSearch.validate()) {
        return;
      }

      this.loadingSearchButton = true;
      this.isDisabledBeneficiaryData = true;

      await this.getBeneficiaries();

      this.loadingSearchButton = false;
      this.isDisabledBeneficiaryData = false;
    },
    async getBeneficiaries() {
      const cpfRequest = this.beneficiary.cpf ? this.formatter.removeNonDigit(this.beneficiary.cpf) : null;
      const isCpfValid = cpfRequest ? cpfRequest.length === 11 : false;

      if (isCpfValid) {
        await this.getBeneficiaryByPayroll();
      } else {
        await this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPayroll() {
      try {
        const cpfRequest = this.formatter.removeNonDigit(this.beneficiary.cpf);
        const queryString = `?documentNumber=${cpfRequest}`;
        const response = await this.payrollService.FindAllByFilters(queryString);
        if (response.data.content && response.data.content.length > 0) {
          this.beneficiaries = response.data.content;
          this.dialog.show = true;
        } else {
          await this.getBeneficiaryByPhysicalPerson();
        }
      } catch (error) {
        this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPhysicalPerson() {
      try {
        const beneficiaryCpf = this.beneficiary.cpf ? this.beneficiary.cpf.replace(/\D/g, '') : null;
        const beneficiaryName = this.beneficiary.name;
        const payload = {
          isActive: true,
          cpf: beneficiaryCpf,
          name: beneficiaryName,
        };

        const validFilters = Object.entries(payload).filter(([, value]) => value !== null).map(([key, value]) => `${key}=${value}`);
        const queryString = validFilters.length > 0 ? `?${validFilters.join('&')}` : '';
        const response = await this.physicalPersonService.FindAllByFiltersQuery(queryString);

        if (!response.data.empty) {
          this.beneficiaries = await response.data;
          this.dialog.show = true;
        } else {
          this.showNotFoundAlert();
        }
      } catch (error) {
        this.handleError();
      }
    },
    handleError() {
      this.alert.show = true;
      this.alert.text = this.alert.error.genericError;
      this.closeAlert();
    },
    showNotFoundAlert() {
      this.alert.show = true;
      this.alert.text = this.alert.error.notFound;
      this.clearBeneficiaryData();
      this.closeAlert();
    },
    clearBeneficiaryData() {
      this.beneficiary.cpf = null;
      this.beneficiary.name = null;
      this.beneficiary.admissionDate = null;
    },
    async loadFinancialGroups() {
      this.isLoadingFinancialGroups = true;
      await this.financialGroupService.FindAll().then((response) => {
        if (response && response.data) {
          this.financialGroups = response.data;
          if (this.financialGroups && this.financialGroups.length === 1) {
            this.beneficiary.plans[0].financialGroup = this.financialGroups[0].id;
            this.financialGroupDefault = this.beneficiary.plans[0].financialGroup;
            this.loadContractByFinancialGroup(this.beneficiary.plans[0]);
          }
        }
        this.isLoadingFinancialGroups = false;
      }).catch(() => {
        this.isLoadingFinancialGroups = false;
      });
    },
    loadContractByFinancialGroup(item) {
      if (this.beneficiary.plans && this.beneficiary.plans.length === 1) {
        this.financialGroupDefault = item.financialGroup;
      }
      // eslint-disable-next-line no-param-reassign
      item.contracts = [];
      // eslint-disable-next-line no-param-reassign
      item.contract = this.isCriticizedCarrier ? item.contract : null;
      // eslint-disable-next-line no-param-reassign
      item.subcontracts = [];
      // eslint-disable-next-line no-param-reassign
      item.subcontract = this.isCriticizedCarrier ? item.subcontract : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plan = this.isCriticizedCarrier ? item.plan : null;
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      if (item.financialGroup !== null && item.financialGroup === this.financialGroupDefault) {
        this.isLoadingContract = true;
        let query = `financialGroupIds=${item.financialGroup}&onlyMainFields=true`;

        if (this.isRHProtegido === true) {
          query += `&allowMovementByProtectedHR=${this.isRHProtegido}`;
        }
        this.contractService.FindAllByFilters(query).then((response) => {
          // eslint-disable-next-line no-param-reassign
          item.contracts = response.data;
          if (response && response.data && response.data.length) {
            const subContractsFalse = response.data.filter((contracts) => contracts.subcontract === false);
            const contracts = [];
            subContractsFalse.forEach((contract) => {
              let text = '';
              text = (`${(contract.benefit_id && contract.benefit_id.name ? contract.benefit_id.name : contract.benefit_id ? contract.benefit_id : '-')} - ${(contract.carrier_id && contract.carrier_id.xipp_commercial_name ? contract.carrier_id.xipp_commercial_name : '-')} - ${contract.policy ? contract.policy : '-'} - ${contract.contract_owner_id && contract.contract_owner_id.legal_name ? contract.contract_owner_id.legal_name : '-'}`);

              if (contract.carrier_id) {
                contracts.push({
                  accountID: contract.carrier_id.id,
                  contract: contract.id,
                  billing: contract.billing,
                  text,
                });
              }
            });
            // eslint-disable-next-line no-param-reassign
            item.contracts = contracts;
            // eslint-disable-next-line no-param-reassign
            item.subcontracts = [];
            this.isLoadingContract = false;

            if (this.isCriticizedCarrier && item.subcontract) {
              this.loadSubcontractByContractId(item);
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            item.contracts = [];
            // eslint-disable-next-line no-param-reassign
            item.subcontracts = [];
            this.isLoadingContract = false;
          }
        }).catch(() => {
          this.isLoadingContract = false;
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        item.contracts = [];
        // eslint-disable-next-line no-param-reassign
        item.contract = this.isCriticizedCarrier ? item.contract : null;
        // eslint-disable-next-line no-param-reassign
        item.subcontracts = [];
        // eslint-disable-next-line no-param-reassign
        item.subcontract = this.isCriticizedCarrier ? item.subcontract : null;
        // eslint-disable-next-line no-param-reassign
        item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
        // eslint-disable-next-line no-param-reassign
        item.eligibilities = [];
        // eslint-disable-next-line no-param-reassign
        item.plan = this.isCriticizedCarrier ? item.plan : null;
        // eslint-disable-next-line no-param-reassign
        item.plans = [];
      }
    },
    loadSubcontractByContractId(item) {
      // eslint-disable-next-line no-param-reassign
      item.subcontracts = [];
      // eslint-disable-next-line no-param-reassign
      item.subcontract = this.isCriticizedCarrier ? item.subcontract : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plan = this.isCriticizedCarrier ? item.plan : null;
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      if (item.contract !== null) {
        this.isLoadingSubContract = true;
        const query = 'onlyMainFields=true';
        this.contractService.FindByParentIdByFilters(item.contract, query).then((response) => {
          if (response && response.data && response.data.length) {
            const subContractsTrue = response.data.filter((contracts) => contracts.subcontract === true);
            const subcontracts = subContractsTrue.map((subcontract) => {
              const subcontractNumber = subcontract.subcontract_number || '-';
              const contractOwnerLegalName = (subcontract.contract_owner_id && subcontract.contract_owner_id.legal_name) || '-';
              const text = `${subcontractNumber} - ${contractOwnerLegalName}`;

              if (subcontract.carrier_id) {
                this.insuranceCarrierId = subcontract.carrier_id.id;
                return {
                  accountID: subcontract.carrier_id.id,
                  subcontract: subcontract.id,
                  billing: subcontract.billing,
                  insuranceCarrierId: subcontract.carrier_id.id,
                  text,
                };
              }
              return null;
            }).filter((subcontract) => subcontract !== null);

            subcontracts.sort((a, b) => a.text.localeCompare(b.text, { numeric: true }));

            // eslint-disable-next-line no-param-reassign
            item.subcontracts = subcontracts;

            if (this.isCriticizedCarrier && item.subcontract) {
              this.loadEligibilityByContractIdAndSubcontractId(item);
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            item.subcontracts = [];
          }
          this.isLoadingSubContract = false;
        }).catch(() => {
          this.isLoadingSubContract = false;
        });
      }
    },
    async loadEligibilityByContractIdAndSubcontractId(item) {
      // eslint-disable-next-line no-param-reassign
      item.eligibility = this.isCriticizedCarrier ? item.eligibility : null;
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plan = this.isCriticizedCarrier ? item.plan : null;
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      if (item.contract !== null && item.subcontract !== null) {
        this.isLoadingEligibility = true;
        const query = `${item.contract}?subcontractId=${item.subcontract}`;
        await this.contractEligibilityService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data) {
            const contractEligibilitiesMap = response.data.map((e) => ({
              key: e.elegibility_id.id,
              value: e.id,
            }));

            const uniqueRecords = response.data.reduce((acc, obj) => {
              const duplicatedIndex = acc.findIndex((it) => it.elegibility_id.id === obj.elegibility_id.id);
              if (duplicatedIndex === -1) {
                acc.push(obj);
              }
              return acc;
            }, []);

            uniqueRecords.forEach((record) => {
              const contractEligibilities = contractEligibilitiesMap.filter(({ key }) => key === record.elegibility_id.id).map(({ value }) => value);
              this.$set(record.elegibility_id, 'contractEligibilities', contractEligibilities);
            });

            item.eligibilities = uniqueRecords;

            item.eligibilities.forEach((eligibility) => {
              const found = eligibility.elegibility_id.contractEligibilities.includes(item.eligibility);
              if (found) {
                this.$set(eligibility, 'id', item.eligibility);
              }
            });
          }

          this.isLoadingEligibility = false;
          if (this.isCriticizedCarrier && item.eligibility) {
            item.eligibilities.forEach((element) => {
              if (element.id === item.eligibility) {
                item.eligibility = element.id;
              }
            });
            this.loadPlansByEligibility(item);
          }
        }).catch(() => {
          this.isLoadingEligibility = false;
        });
      }
    },
    loadPlansByEligibility(item) {
      item.plan = this.isCriticizedCarrier ? item.plan : null;
      item.plans = [];

      if (item.eligibility !== null) {
        this.isLoadingPlan = true;
        const eligibilityFound = item.eligibilities.find((eligibility) => eligibility.id === item.eligibility);
        let query = '';
        if (eligibilityFound && eligibilityFound.elegibility_id.contractEligibilities) {
          query = `?contractEligibilitiesIds=${eligibilityFound.elegibility_id.contractEligibilities}`;
        } else if (eligibilityFound && eligibilityFound.id) {
          query = `?contractEligibilitiesIds=${eligibilityFound.id}`;
        }

        this.contractProductService.FindAllByFilters(query).then((response) => {
          const res = response.data;
          const plans = [];
          res.forEach((element) => {
            plans.push({
              eligibility_id: element.eligibility_id,
              ...element.plan_id,
              name: `${element.plan_id.name} - ${element.plan_id.code}`,
            });
            // eslint-disable-next-line no-param-reassign
            item.plans = plans;
          });
          this.isLoadingPlan = false;
        }).catch(() => {
          this.isLoadingPlan = false;
        });
      }
    },
    sortPlans(plans, item) {
      if (plans.length) {
        plans.sort((a, b) => (a.text > b.text ? 1 : -1));
      }
      // eslint-disable-next-line no-param-reassign
      item.plans = plans;
    },
    hasDuplicatedContracts() {
      const contractIds = this.beneficiary.plans.map((plan) => plan.contract);
      return contractIds.some(
        (contract, idx) => contractIds.indexOf(contract) !== idx,
      );
    },
    isEmptyFilters() {
      return !(this.beneficiary.cpf || this.beneficiary.name);
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementRecordService = new MovementRecordService();
    this.fieldsToValidate = new MovementRecordFormFieldsToValidate();
    this.physicalPersonService = new PhysicalPersonService();
    this.financialGroupService = new FinancialGroupService();
    this.contractService = new ContractService();
    this.contractEligibilityService = new ContractEligibilityService();
    this.contractProductService = new ContractProductService();
    this.payrollService = new PayrollService();
    this.movementType = this.$route.query.movementType;
    this.loading = true;
  },
};
</script>
